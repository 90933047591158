import React, { useState, useEffect } from 'react'
import ".././Safety.css";
import Header from "../../../../components/Yard/common/Header"
import Update from '../../../../components/Yard/update/Update';
import circle_color from "../../../../images/safety/circle_color.svg";
import circle from '../../../../images/safety/circle.svg'
import DetailsBox from '../../../../components/Yard/detailBox/DetailsBox';
import { connect } from "react-redux"
import { truck_content } from "../../../../actions/yardActions/TruckAction";
import TextArea from '../../../../components/Yard/textArea/TextArea';
import Button from '../../../../components/Yard/Button/Button';
import { Link } from "gatsby"
import DateTime from '../../../../components/Yard/dateTime/DateTime';
import {Routes} from '../../../../utils/route';
import {Spin } from 'antd';
const Safety_Part_A = (props) => {
    const id=props.id
    const [state, setState] = useState("");
    const [safyArea, setSafyArea] = useState("please write here");
    const [loading, setLoading] = useState (true);
    useEffect(() => {
        const callback=(val)=>{
            if(val===true){
                setLoading(false);
                props.truck_content(id);
            }}
          Routes('yard',callback)
    }, []);
    useEffect(()=>{
        if (props.single_truck !== null) {
            setState(props.single_truck);
        }
    },[props.single_truck])
    if(!loading){
    return (
        <>
            <section className="safety">
                <Header headName="safety details" LinkName={`/yard/inspection/${id}`} />
                <div className="safety_container">
                    <Update
                        update="safety_update"
                        circleImg_1={circle_color} circleImg_2={circle}
                        circleImg_3={circle} circleImg_4={circle} circleImg_5={circle}
                        border_1="safety_border_1" border_2="safety_border_2"
                        border_3="safety_border_3" border_4="safety_border_4"
                    />
                    <div className="safety_part_a">
                        <p>confined space entry permit</p>
                        <h3>Part A - Detail of work</h3>
                    </div>
                    <DetailsBox
                        head_1="date of entry" head_1_ex={state.update}
                        head_2="equipment" head_2_ex={state.truck_no}
                        head_3="location" head_3_ex="port arthur texas"
                    />
                    <TextArea head="Details of work to be undertaken"
                        value={safyArea} change={setSafyArea}
                    />
                    <DateTime label_1="date" label_2="time" />
                    <div className="safety_double_button">
                        {/* skip button */}<Button className="safety_button_1" name="skip & begin wash" />
                        <Link to={`/yard/safety/safety_part_b/${id}`}><Button className="safety_button_2" name="next" /></Link>
                    </div>
                </div>
            </section>
        </>
    )
}else{
    return(<div className="spinner-main"><Spin/></div>)
  }
}

const mapStateToProps = state => ({
    truckData:state.truck.truckData,
    single_truck:state.truck.single_truck
  })
export default connect(mapStateToProps, {truck_content})(Safety_Part_A)