import React from 'react'
import "./DetailsBox.css"
import NoImage from '../../../images/new/Image Box.svg'

const DetailsBox = (props) => {
    return (
        <>
            {/* <div className="details_box">
                <div>{props.head_1}<p>{props.head_1_ex}</p></div>
                <div>{props.head_2}<p>{props.head_2_ex}</p></div>
                <div>{props.head_3}<p>{props.head_3_ex}</p></div>
                <div>{props.head_4}<p>{props.head_4_ex}</p></div>
            </div> */}
            <div className="details_box">
            <div className='details_box_img'><img src={NoImage} alt="card img" />
            <div className='details_box_status'>STATUS : {props.head_5_ex} </div>
            </div>
            <div className='details_box_content'>
                <div>{props.head_1}<p>{props.head_1_ex}</p></div>
                <div>{props.head_2}<p>{props.head_2_ex}</p></div>
                <div>{props.head_3}<p>{props.head_3_ex}</p></div>
                <div>{props.head_4}<p>{props.head_4_ex}</p></div>
                </div>            
            </div>
        </>
    )
}
export default DetailsBox;