import React from "react"
import "./TextArea.css"

const TextArea = props => {
  return (
    <>
      <div className="textarea_comp">
        <h3>{props.head}</h3>
        <textarea
          value={props.value}
          onChange={e => {
            props.change(e.target.value)
          }}
          placeholder={
            props?.placeholder ? props?.placeholder : "Write something here..."
          }
        ></textarea>
      </div>
    </>
  )
}
export default TextArea
